import { useMemo, useState } from 'react';

import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FIVE } from 'common/constants';
import { CutoffReport } from 'common/types';
import { DateTime, Interval } from 'luxon';
import countries from 'utils/countries';
import { useDownloadCutoffReportMutation } from 'utils/queries/payroll/useDownloadCutoffReportMutation';
import { useListCutoffReportsFilterQuery } from 'utils/queries/payroll/useListCutoffReportsFilterQuery';
import { useListCutoffReportsQuery } from 'utils/queries/payroll/useListCutoffReportsQuery';
import { getDataFromResponse } from 'utils/react-query-utils';

import {
  Box,
  Button,
  Dropdown,
  Inline,
  notifyError,
  notifySuccess,
  ProgressBar,
  Skeleton,
  Split,
  Stack,
  Table,
} from '@omnipresentgroup/design-system';

enum ColumnHeaders {
  country_name = 'Country',
  lsp_name = 'Provider',
  report_name = 'Report',
}

type Filter = {
  country?: string;
  lsp?: string;
  period: {
    from: number;
    to: number;
  };
};

const PAGE_SIZE_OPTIONS = [50, 100];

function getAllPeriods(): Filter['period'][] {
  const startDate = DateTime.fromISO('2024-04-01', { zone: 'utc' });
  const endDate = DateTime.utc().endOf('month');

  const periods = Interval.fromDateTimes(startDate, endDate)
    .splitBy({
      month: 1,
    })
    .reduce(
      (acc, subInterval) => {
        if (!subInterval.start) {
          return acc;
        }

        return [
          ...acc,
          {
            from: subInterval.start.toUnixInteger(),
            to: subInterval.start.endOf('month').toUnixInteger(),
          },
        ];
      },
      [] as Filter['period'][],
    );

  return periods;
}

export const CutoffReportsTab = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const [downloadingList, setDownloadingList] = useState<
    Record<string, number>
  >({});

  const periods = getAllPeriods();

  const [filter, setFilter] = useState<Filter>({
    period: periods[periods.length - 1],
  });

  const {
    data: cutoffReports,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useListCutoffReportsQuery({
    pageSize: pagination.pageSize,
    pageIndex: pagination.pageIndex + 1,
    ...filter,
    ...filter.period,
  });

  const { reports = [], total_count: totalCount = 0 } = cutoffReports || {};

  const pageCount = Math.ceil(totalCount / pagination.pageSize);

  const {
    data: cutoffReportFilters = { lsps: [] },
    isLoading: isLoadingFilters,
  } = useListCutoffReportsFilterQuery();

  const { mutate: downloadReport } = useDownloadCutoffReportMutation();

  const onDownloadClick = (reportId: string) => {
    setDownloadingList((prevList) => ({
      ...prevList,
      [reportId]: 0,
    }));
    downloadReport(
      {
        reportId,
        setProgress: (progress) => {
          setDownloadingList((prevList) => ({
            ...prevList,
            [reportId]: progress,
          }));
        },
      },
      {
        onSuccess: (data) => {
          const file = getDataFromResponse(data);
          const reportName =
            reports.find((report) => report.report_id === reportId)
              ?.report_name || reportId;
          const filename =
            data.headers['content-disposition'].split("'")[1] ||
            `${reportName}.zip`;
          const a = document.createElement('a');

          a.href = window.URL.createObjectURL(file);
          a.download = filename;
          a.click();

          notifySuccess(
            {
              title: 'Success',
              description: 'Downloaded cutoff report',
            },
            {
              autoClose: 5000,
            },
          );
        },
        onError: () => {
          notifyError({
            title: 'Error',
            description:
              'Failed to download cutoff report, please try again later.',
          });
        },
        onSettled: (_data, _error, { reportId }) => {
          setDownloadingList((prevList) => {
            const { [reportId]: _toDelete, ...rest } = prevList;

            return rest;
          });
        },
      },
    );
  };

  const showLoadingTable =
    isLoadingReports || isLoadingFilters || isFetchingReports;

  const columnHelper = createColumnHelper<CutoffReport>();

  const columns: ColumnDef<CutoffReport, any>[] = [
    columnHelper.accessor('country_name', {
      cell: (info) => info.getValue(),
      header: () => ColumnHeaders.country_name,
    }),
    columnHelper.accessor('lsp_name', {
      cell: (info) => info.getValue(),
      header: () => ColumnHeaders.lsp_name,
    }),
    columnHelper.accessor('report_name', {
      cell: (info) => (
        <Box
          css={{
            whiteSpace: 'nowrap',
          }}
        >
          {info.getValue()}
        </Box>
      ),
      header: () => ColumnHeaders.report_name,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        const downloadProgress = downloadingList[props.row.original.report_id];

        const isDownloading = downloadProgress >= 0;

        return (
          <Inline>
            {isDownloading ? (
              <ProgressBar
                id={`${props.row.original.report_id}_progress`}
                progress={downloadProgress}
                showProgressNumber
              />
            ) : (
              <Button
                variant="secondary"
                size="small"
                onClick={() => onDownloadClick(props.row.original.report_id)}
              >
                Download
              </Button>
            )}
          </Inline>
        );
      },
    }),
  ];

  const LoadingColumns: ColumnDef<any, any>[] = [
    columnHelper.display({
      id: 'country_name',
      cell: () => <Skeleton height={16} />,
      header: () => ColumnHeaders.country_name,
      size: 200,
    }),
    columnHelper.display({
      id: 'lsp_name',
      cell: () => <Skeleton height={16} />,
      maxSize: 20,
      header: () => ColumnHeaders.lsp_name,
      size: 16,
    }),
    columnHelper.display({
      id: 'report_name',
      cell: () => <Skeleton height={16} />,
      maxSize: 20,
      header: () => ColumnHeaders.report_name,
      size: 16,
    }),
    columnHelper.display({
      id: 'download',
      cell: () => <Skeleton height={16} />,
      maxSize: 20,
      header: () => '',
      size: 16,
    }),
  ];

  const table = useReactTable({
    data: showLoadingTable
      ? Array.from({ length: FIVE }, (_value, index) => index)
      : reports,
    columns: showLoadingTable ? LoadingColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination },
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  const providerOptions = useMemo(() => {
    return cutoffReportFilters.lsps
      .map((lsp) => ({
        label: lsp.lsp_name,
        value: lsp.lsp_id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [cutoffReportFilters]);

  const countryOptions = useMemo(() => {
    return countries
      .map((country) => ({
        label: country.label,
        value: country.value,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  const periodOptions = useMemo(() => {
    return periods.map((period, index) => ({
      label: DateTime.fromMillis(period.from * 1000, { zone: 'utc' }).toFormat(
        'LLLL yyyy',
      ),
      value: index,
    }));
  }, [periods]);

  return (
    <>
      <Stack bg="primary" radius="m" border="subtle" gap="0">
        <Split fraction="1/2">
          <Inline p="16">
            <Box css={{ flex: 2 }}>
              <Dropdown
                id="country"
                label="Country"
                placeholder="Filter by country"
                value={countryOptions.find(
                  (option) => option.value === filter.country,
                )}
                options={countryOptions}
                isSearchable
                isClearable
                onChange={(newValue) => {
                  if (
                    newValue !== null &&
                    'value' in newValue &&
                    typeof newValue.value === 'string'
                  ) {
                    const country = newValue.value;
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      country,
                    }));
                    return;
                  }
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    country: undefined,
                  }));
                }}
              />
            </Box>
            <Box css={{ flex: 3 }}>
              <Dropdown
                id="provider"
                label="Provider"
                placeholder="Filter by provider"
                value={providerOptions.find(
                  (option) => option.value === filter.lsp,
                )}
                options={providerOptions}
                isSearchable
                isClearable
                onChange={(newValue) => {
                  if (
                    newValue !== null &&
                    'value' in newValue &&
                    typeof newValue.value === 'string'
                  ) {
                    const lsp = newValue.value;
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      lsp,
                    }));
                    return;
                  }

                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    lsp: undefined,
                  }));
                }}
              />
            </Box>
            <Box css={{ flex: 1 }}>
              <Dropdown
                id="period"
                label="Period"
                placeholder="Filter by period"
                value={
                  periodOptions[
                    periods.findIndex(
                      (period) =>
                        filter.period.from === period.from &&
                        filter.period.to === period.to,
                    )
                  ]
                }
                options={periodOptions}
                isSearchable
                onChange={(newValue) => {
                  if (newValue !== null && 'value' in newValue) {
                    const period = periods[Number(newValue.value)];
                    setFilter((prevFilter) => ({ ...prevFilter, period }));
                  }
                }}
              />
            </Box>
          </Inline>
        </Split>
        <Table
          table={table}
          wrapperProps={{
            maxH: '60vh',
            overflow: 'visible',
            style: { border: 'none' },
          }}
          showHeader={false}
          usePagination
          paginationProps={{
            canNextPage: pagination.pageIndex + 1 < pageCount,
            pageCount,
            canPreviousPage: pagination.pageIndex !== 0,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
          }}
        />
      </Stack>
    </>
  );
};
