import { employmentTimeClient } from 'api';

import {
  CompanyTimeOffLogsResponse,
  CreateTimeOffLogsPayload,
  EmployeeTimeOffLogsResponse,
  TimeOffLog,
} from '../types';

export const TIME_OFF_LOGS_BASE_API_ENDPOINT = '/timeoff';

export const composeTimeOffLogsEndpointByScope = (
  scope: 'employee' | 'company',
  id: string,
) => `${TIME_OFF_LOGS_BASE_API_ENDPOINT}/by-${scope}-id/${id}/requests`;

export const getEmployeeTimeOffLogsRequest = (employeeId: string) =>
  employmentTimeClient.get<EmployeeTimeOffLogsResponse>(
    composeTimeOffLogsEndpointByScope('employee', employeeId),
  );

export const getEmployeeTimeOffLogsByYearMonthRequest = (
  employeeId: string,
  yearMonth: string,
) =>
  employmentTimeClient.get<EmployeeTimeOffLogsResponse>(
    `${TIME_OFF_LOGS_BASE_API_ENDPOINT}/by-employee-id/${employeeId}/requests?year_month=${yearMonth}`,
  );

export const createEmployeeTimeOffLogRequest = (
  newTimeOff: CreateTimeOffLogsPayload,
) =>
  employmentTimeClient.post<TimeOffLog>(
    TIME_OFF_LOGS_BASE_API_ENDPOINT,
    newTimeOff,
  );

export const getCompanyTimeOffLogsRequest = (companyId: string) =>
  employmentTimeClient.get<CompanyTimeOffLogsResponse>(
    composeTimeOffLogsEndpointByScope('company', companyId),
  );

export const deleteEmployeeTimeOffLogRequest = (timeOffLogid: string) =>
  employmentTimeClient.delete<void>(
    `${TIME_OFF_LOGS_BASE_API_ENDPOINT}/requests/${timeOffLogid}`,
  );
