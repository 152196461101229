import { createQueryKeys } from 'utils/react-query-utils';

export const timeOffLogsKeys = createQueryKeys(
  'time-off-logs',
  (createKey) => ({
    singleEmployeeByYearMonth: (employeeId: string, yearMonth: string) =>
      createKey(employeeId, yearMonth),
    singleEmployee: (employeeId: string) => createKey(employeeId),
    singleCompany: (companyId: string) => createKey(companyId),
  }),
);
