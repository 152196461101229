import { UserRoleType } from 'common/types';
import {
  ADMIN_LIST_PAGE,
  ALL_EMPLOYEES_PAGE,
  BENEFITS_PAGE,
  COMPANIES_LIST_PAGE,
  FINANCE_PAGE,
  LSP_EMPLOYEES_PAGE,
  LSP_EXPENSES_PAGE,
  LSP_LIST_PAGE,
  LSP_PROFILE_PAGE,
  PAYROLL_PAGE,
  PROVIDERS_PAGE,
} from 'paths';
import {
  hasFinanceScope,
  hasPayrollScope,
  hasSuperManagerScope,
  isBenefitsAdmin,
  isFinanceAdmin,
  isOPAdmin,
  isPayrollAdmin,
} from 'utils/user';

import type { NavMenuItems } from '@omnipresentgroup/design-system/dist/types/components/Navigation/NavMenu';
import {
  employeeNavItems,
  managerNavItems,
  NavigateFunction,
} from '@omnipresentgroup/nav-items';

import appHistory from './appHistory';
import { TransformedUser } from './store/app.transformers';

const navigateAdapter: NavigateFunction = (path: string) => {
  appHistory.push(path);
};

const omniplatformAppUrl = process.env.VITE_OMNIPLATFORM_APP;
const managerAppUrl = process.env.VITE_MANAGER_APP;
const employeeAppUrl = process.env.VITE_EMPLOYEE_APP;

if (!omniplatformAppUrl || !managerAppUrl) {
  throw new Error('Missing app URLs for OmniPlatform and Manager apps');
}

const adminNavItems = ({
  isPayrollAdminUser,
  isBenefitsAdminUser,
  isFinanceAdminUser,
  isOPAdminUser,
}: {
  isPayrollAdminUser?: boolean;
  isBenefitsAdminUser?: boolean;
  isFinanceAdminUser?: boolean;
  isOPAdminUser?: boolean;
}) =>
  [
    {
      name: 'Home',
      icon: 'Home',
      onClick: () => appHistory.push(COMPANIES_LIST_PAGE),
      id: COMPANIES_LIST_PAGE,
    },
    {
      name: 'LSPs',
      icon: 'Scale',
      onClick: () => appHistory.push(LSP_LIST_PAGE),
      id: LSP_LIST_PAGE,
    },
    {
      name: 'Providers',
      icon: 'Building',
      onClick: () => appHistory.push(PROVIDERS_PAGE),
      id: PROVIDERS_PAGE,
    },
    {
      name: 'Employees',
      icon: 'UserCheck',
      onClick: () => appHistory.push(ALL_EMPLOYEES_PAGE),
      id: ALL_EMPLOYEES_PAGE,
    },
    ...(isPayrollAdminUser || isBenefitsAdminUser || isFinanceAdminUser
      ? [
          {
            name: 'Payroll',
            icon: 'FileCertificate',
            onClick: () => appHistory.push(PAYROLL_PAGE),
            id: PAYROLL_PAGE,
          },
        ]
      : []),
    ...(isBenefitsAdminUser
      ? [
          {
            name: 'Benefits',
            icon: 'Gift',
            onClick: () => appHistory.push(BENEFITS_PAGE),
            id: BENEFITS_PAGE,
          },
        ]
      : []),
    ...(isFinanceAdminUser
      ? [
          {
            name: 'Finance',
            icon: 'ChartPie',
            onClick: () => appHistory.push(FINANCE_PAGE),
            id: FINANCE_PAGE,
          },
        ]
      : []),
    ...(isOPAdminUser
      ? [
          {
            name: 'Admin users',
            icon: 'ShieldLock',
            onClick: () => appHistory.push(ADMIN_LIST_PAGE),
            id: ADMIN_LIST_PAGE,
          },
        ]
      : []),
  ] as NavMenuItems;

const lspNavItems = () =>
  [
    {
      name: 'Home',
      icon: 'Home',
      onClick: () => appHistory.push(LSP_EMPLOYEES_PAGE),
      id: LSP_EMPLOYEES_PAGE,
    },
    {
      name: 'Expenses',
      icon: 'Wallet',
      onClick: () => appHistory.push(LSP_EXPENSES_PAGE),
      id: LSP_EXPENSES_PAGE,
    },
    {
      name: 'Profile',
      icon: 'User',
      onClick: () => appHistory.push(LSP_PROFILE_PAGE),
      id: LSP_PROFILE_PAGE,
    },
  ] as NavMenuItems;

export const getNavItems = ({
  user,
  userRole,
  enablePayrollReportsTab = false,
  enableTimesheetsArbeitszeit = false,
  enableNewAdditionalPay = false,
  enableNewIntegration = false,
}: {
  user: TransformedUser | undefined;
  userRole?: UserRoleType;
  enablePayrollReportsTab?: boolean;
  enableMyEmployment?: boolean;
  enableTimesheetsArbeitszeit?: boolean;
  enableNewAdditionalPay?: boolean;
  enableNewIntegration?: boolean;
}): NavMenuItems => {
  const isSuperManager = !!user && hasSuperManagerScope(user);
  const isPayrollAdminUser = !!user && isPayrollAdmin(user);
  const isBenefitsAdminUser = !!user && isBenefitsAdmin(user);
  const isFinanceAdminUser = !!user && isFinanceAdmin(user);
  const isOPAdminUser = !!user && isOPAdmin(user);
  const isFinanceManager = !!user && hasFinanceScope(user);
  const isPayrollManager = !!user && hasPayrollScope(user);

  switch (userRole) {
    case 'manager':
      return managerNavItems({
        permissions: {
          isSuperManager,
          isFinanceManager,
          isPayrollManager,
        },
        featureFlags: {
          enablePayrollReportsTab,
          enableTimesheetsArbeitszeit,
          enableNewAdditionalPay,
          enableNewIntegration,
        },
        navigate: navigateAdapter,
        appUrls: {
          omniplatform: omniplatformAppUrl,
          manager: managerAppUrl,
        },
      });
    case 'employee':
      return employeeNavItems({
        permissions: {},
        navigate: navigateAdapter,
        appUrls: { employee: employeeAppUrl, omniplatform: omniplatformAppUrl },
      });
    case 'admin':
      return adminNavItems({
        isPayrollAdminUser,
        isBenefitsAdminUser,
        isFinanceAdminUser,
        isOPAdminUser,
      });
    case 'lsp':
      return lspNavItems();
    default:
      return [];
  }
};
