import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import appHistory from 'app/appHistory';
import { getNavItems } from 'app/navItems';
import { TransformedUser } from 'app/store/app.transformers';
import { EmployeeForDetailView, UserRoleType } from 'common/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getEmployeeSelector } from 'omniplatform/employee/store/selectors/employee.selector';
import { ACCOUNT_DETAILS_PAGE } from 'paths';
import { useSelector } from 'react-redux';

import { Navigation } from '@omnipresentgroup/design-system';
import { NavMenuItems } from '@omnipresentgroup/design-system/dist/types/components/Navigation/NavMenu';
import { NavSectionProps } from '@omnipresentgroup/design-system/dist/types/components/Navigation/NavSection';

/* eslint-disable no-loops/no-loops */
export const useNavigation = ({
  userRole,
  user,
}: {
  userRole: UserRoleType;
  user: TransformedUser | undefined;
}) => {
  const location = useLocation();

  // Use Auth0 user in order to load the sidebar asap
  const { user: userFromAuth0, logout } = useAuth0();
  const selectedEmployee = useSelector<any, EmployeeForDetailView>(
    getEmployeeSelector,
  );
  const {
    enablePayrollReportsTab,
    enableTimesheetsArbeitszeit,
    enableNewAdditionalPay,
    enableNewIntegration,
  } = useFlags();

  const navItems = getNavItems({
    user,
    userRole,
    enablePayrollReportsTab,
    enableMyEmployment: !!selectedEmployee?.employmentInformation,
    enableTimesheetsArbeitszeit,
    enableNewAdditionalPay,
    enableNewIntegration,
  });

  const findSelectedItemId = (items: NavMenuItems) => {
    for (const item of items) {
      // Handle special case for the 'Home'
      if (item.id === '/' && location.pathname === '/') {
        return '/';
      }

      // Check sub-items first
      const subItemMatch = (item as NavSectionProps).items?.find((subItem) =>
        location.pathname.startsWith(subItem.id),
      );

      if (subItemMatch) {
        return subItemMatch.id;
      }

      // Check the main item if no sub-item matches
      if (item.id !== '/' && location.pathname.startsWith(item.id)) {
        return item.id;
      }
    }

    return '';
  };

  const NavigationMenu = () => (
    <Navigation
      footerItems={[
        {
          icon: 'Logout',
          id: '/logout',
          name: 'Logout',
          onClick: () => logout({ returnTo: window.location.origin }),
        },
      ]}
      mainItems={navItems}
      selectedItemId={findSelectedItemId(navItems)}
      userProfile={{
        companyName: '', // TODO: a bit tricky as this info is not available in the same way given roles
        onClick: () => appHistory.push(ACCOUNT_DETAILS_PAGE),
        userName: userFromAuth0?.name || '',
        userRole,
      }}
    />
  );

  return { Navigation: NavigationMenu };
};
